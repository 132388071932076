import 'bootstrap/dist/css/bootstrap.min.css';
import Nav2 from './Nav2';
import { Container, Row, Col } from 'react-bootstrap';



const about = ()=>{

    return( 
        
        <div>    
         
<Nav2 />

<Container className="text-center mt-5">

<h2 className='radonc' >منذ ٦ سنوات وشرکة رادون هی الموزع الرئیسی و الوکیل الحصری لدهانات الجزیرة فی اقلیم کردستان العراق</h2>
<br/>    <br/>

<img src={require("./../src/img/log.png")} alt="Descriptive Alt Text" className="img-fluid" />

<h2  >دهانات الجزيرة حددت هدفها و باشرت في طريق النجاح حيث اختارت ان يكون الدهان و الطلاء وحلول البناء رسالتها المبتكرة التي في محتواها اجود المنتجات بافضل المعاير دهانات الجزيرة لديها ٥ مصانع ضخمه يعمل بعضها بشكل متواصل على مدار الساعة بتقنيات متطورة للغاية ٫ ٤٥٠ منتج مختلف و اکثر من ٧٥٠ صالة عرض في منطقة الخليج و الشرق الأوسط مع اسطول شحن خاص بها يضم أفضل الخبراء بهذا المجال</h2>
<br/>    <br/>    <br/>
<h1 className='radonc'>منتجاتنا</h1>
<br/>
     <h2>
تقوم «دهانات الجزيرة» بتصنيع أكثر من 450 منتج مختلف، تتوزع بين الطلاء المعماري، والزخرفي والصناعي والبحري، وطلاء الحماية، وطلاء الخشب، ومنتجات الطلاء المقاومة للحريق، وغيرها، حيث صُممت هذه المنتجات بعناية فائقة لتحاكي متطلبات عملائنا وتلبي احتياجاتهم
</h2>

<Row className="mt-4">
        <Col xs={6} md={3}>
          <img src={require("./../src/img/1e.png")} width="200"
            height="200" alt="Logo 1" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/2e.png")} width="200"
            height="200" alt="Logo 2" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/3e.png")} width="200"
            height="200" alt="Logo 3" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/4e.png")} width="200"
            height="200" alt="Logo 4" className="img-fluid" />
        </Col>
      </Row>
      <br/>
      <Row className="mt-4">
        <Col xs={6} md={3}>
          <img src={require("./../src/img/5e.png")} width="200"
            height="200" alt="Logo 1" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/6e.png")} width="200"
            height="200" alt="Logo 2" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/7e.png")} width="200"
            height="200" alt="Logo 3" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/8e.png")} width="200"
            height="200" alt="Logo 4" className="img-fluid" />
        </Col>
      </Row>
      <br/>
      <Row className="mt-4">
        <Col xs={6} md={3}>
          <img src={require("./../src/img/9e.png")} width="200"
            height="200" alt="Logo 1" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/10e.png")} width="200"
            height="200" alt="Logo 2" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/11e.png")} width="200"
            height="200" alt="Logo 3" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/12e.png")} width="200"
            height="200" alt="Logo 4" className="img-fluid" />
        </Col>
      </Row>

      <br/><br/>   <hr/>   <br/><br/>
      <h1 className='radonc'>الشهادات</h1>

    <h2>  «حاز طلاء «الجزيرة فاير دامب» المقاوم للحريق على شهادة «إكسوفا وارينجتون فاير»</h2>

    <img src={require("./../src/img/9sh.png")}
            alt="Logo 1" className="img-fluid" />


      <Row className="mt-4">
        <Col xs={6} md={3}>
          <img src={require("./../src/img/1sh.png")} width="200"
            height="200" alt="Logo 1" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/2sh.png")} width="200"
            height="200" alt="Logo 2" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/3sh.png")} width="200"
            height="200" alt="Logo 3" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/4sh.png")} width="200"
            height="200" alt="Logo 4" className="img-fluid" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6} md={3}>
          <img src={require("./../src/img/5sh.png")} width="200"
            height="200"  alt="Logo 5" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/6sh.png")} width="200"
            height="200" alt="Logo 6" className="img-fluid" />
        </Col>
        <Col xs={6} md={3}>
          <img src={require("./../src/img/7sh.png")} width="200"
            height="200" alt="Logo 7" className="img-fluid" />
        </Col> 
        <Col xs={6} md={3}>
          <img src={require("./../src/img/8sh.png")} width="200"
            height="200" alt="Logo 8" className="img-fluid" />
        </Col>
      </Row>

    </Container>
        </div>
    );
}


export default about;