import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link } from 'react-router-dom';


const Nav2 = ()=>{

    return( 
        
      
        <div>    
          <Navbar bg="white" expand="lg" dir="rtl">
      <Container>
        <Navbar.Brand href="#home">    <img
        
            src={require("./../src/radon.png")}
            width="200"
            height="200"
            className="d-inline-block align-top"
            alt="Brand logo"
          /> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link reloadDocument  as={Link} to="/" >الصفحــة الرئیسیة</Nav.Link>
            <Nav.Link reloadDocument  as={Link} to="/classnumber" >المشاریع</Nav.Link>
            <Nav.Link reloadDocument as={Link} to="/about" >حول</Nav.Link>
            <Nav.Link reloadDocument as={Link} to="/location" >العنوان</Nav.Link>
            <Nav.Link reloadDocument  as={Link} to="/contact" >الاتصال</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    
        </div>
         
    );
}


export default Nav2;