import Nav2 from './Nav2';




const classnumber = ()=>{

    return( 
        <div>
{/* ./../src/img/imgclass.jpg
 */}

<Nav2 />

<div className="container mt-4">
    <div className="row">
    <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/24.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"align="right" >مجمع جاوی سلیمانی</h5>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/42.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right" >مجمع باران السكني / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/5.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مصفاة النفط الذهبي / نجف</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/6.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مجمع جناءن السکني / فلوجە</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/7.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"  align="right" >مجمع امیرات / نجف</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/41.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مجمع  قيوان ستي / السلیمانیة</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    





  <div className="container mt-4">
    <div className="row">
    <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/8.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"align="right" >مجمع اعالي قيوان / السلیمانیة </h5>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/9.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right" >مجمع جوار جرا السكني / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/10.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مجمع اعالي  سلیمانیة / سليمانية </h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/28.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">کوردستان موتورز</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/29.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"  align="right" >قاعة العاب الاطفال تایجر تاون</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/30.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">معالجة</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
    









  <div className="container mt-4">
    <div className="row">
    <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/23.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"align="right" >مجمع دروازة ستي / السلیمانیة</h5>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/39.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right" >روضة للأطفال / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/25.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">تجربة مکان فلکلوري بمنتج مشاشکو</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/26.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">جامع سیدنا ابراهیم / السلیمانیة </h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/27.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"  align="right" >شرکة یعقوب للمواد الأنشائیة / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/43.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مجمع الروان السكني / بغداد</h5>
          </div>
        </div>
      </div>
    </div>
  </div>











  <div className="container mt-4">
    <div className="row">
    <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/31.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"align="right" >اینزا هوم للأثاث/ السلیمانیة </h5>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/32.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right" >دیکور هوم للأثاث / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/44.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مجمع ستي تاورز السكني / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/34r.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">جامع نورسي / السلیمانیة</h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/35.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"  align="right" >وحدة سکنیة من مجمع اعالي السلیمانیة </h5>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/36.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">طلاء وحدة سکنیة من الداخل / السلیمانیة</h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="container mt-4">
    <div className="row">
    <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/37.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title"align="right" >مجمع شا ستیي/ السلیمانیة</h5>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/38.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right" >جدار شارع سالم  / السلیمانیة</h5>
          </div>
        </div>
      </div>
   
      <div className="col-md-4 mb-4">
        <div className="card">
          <div className="card-body">
          <img
                src={require("./../src/img/40.png")}
                 // Path to your image
                className="card-img-top"
                alt="Card 1"
              />
              <hr/>
            <h5 className="card-title" align="right">مرکز للأسنان</h5>
          </div>
        </div>
      </div>
     

    </div>
  </div>
        </div>
    );
}


export default classnumber;