import { Container } from 'react-bootstrap';
import Nav2 from './Nav2';




const classnumber = ()=>{

    return( 
        <div>
            
<Nav2 />
<Container>
<img src={require("./../src/img/l1.png")}
            alt="Logo 1" className="img-fluid" />
<br/><br/>
    <h2 className='radd'> 07701857874 - 07701857874 : فرع اربیل </h2>

<br/><br/><br/>
<img src={require("./../src/img/l2.png")}
            alt="Logo 1" className="img-fluid" />
<br/><br/>
    <h2 className='radd'> 07700121222 - 07725425037 : فرع السلیمانیة</h2>
</Container>

        </div>
    );
}


export default classnumber;