import Nav2 from './Nav2';
import { Container, Row, Col, Image  } from 'react-bootstrap';
import './App.css';
import SwipeableViews from 'react-swipeable-views';
import image1 from './img2/st1.png';
import image2 from './img2/st2.png';
import image3 from './img2/st3.png';
import image4 from './img2/st4.png';
import image5 from './img2/st5.png';
import image6 from './img2/st6.png';
import image7 from './img2/st7.png';
import image8 from './img2/st8.png';
import image9 from './img2/st9.png';
import image10 from './img2/st10.png';
import image11 from './img2/st11.png';
import image12 from './img2/st12.png';


const items = [
  { id: 1, image: image1 ,name: 'الجزیرة کوریوم' },
  { id: 2, image: image2 ,name: 'الجزیرة برایم'},
  { id: 3, image: image3 ,name: 'الجزیرة جرین'},
  { id: 4, image: image4 ,name: 'الجزیرة لاینتکس' },
  { id: 5, image: image5 ,name: 'الجزیرة ماجنتیک'},
  { id: 6, image: image6 ,name: 'الجزیرة مکسیم'},
  { id: 7, image: image7 ,name: 'الجزیرة نوفل'},
  { id: 8, image: image8 ,name: 'الجزیرة روشن'},
  { id: 9, image: image9  ,name: 'الجزیرة رویال برایم' }, 
  { id: 10, image: image10  ,name: 'الجزیرة رویال' },
  { id: 11, image: image11 ,name: 'الجزیرة آلتیمیت'},
  { id: 12, image: image12 ,name: 'الجزیرة نوتس'},



  // Add more images for other items
];
const chunkedItems = [];
for (let i = 0; i < items.length; i += 4) {
  chunkedItems.push(items.slice(i, i + 4));
}

const classnumber = ()=>{

    return( 
      <div className="p-3 mb-5 bg-white rounded shadow">
       <Container className="mt-5 text-center">
              <Nav2 />
              <hr className="shadowed-hr" />
      <Row className="align-items-center">
        <Col  xs={12} md={6}>
          <img src={require("./../src/img/log.png")} alt="Descriptive Alt Text" className="img-fluid" />
        </Col>
        <Col xs={12}  md={6}>
          <h1>شركة رادون</h1>
          <h2>شركة رادون الوكيل الحصري لدهانات الجزيرة في إلاقليم كردستان العراق </h2>

        </Col>
      </Row>
<br/>
      <Row>
          <Col>
          <SwipeableViews enableMouseEvents>
              {chunkedItems.map((chunk, index) => (
                <div key={index} style={{ display: 'flex' }}>
                  {chunk.map(item => (
                    <div key={item.id} style={{ flex: '1 0 0' }}>
                      <img src={item.image} alt={`Item ${item.id}`} style={{ width: '50%' }} />
                      <h4>{item.name}</h4>

                    </div>
                  ))}
                </div>
              ))}
            </SwipeableViews>
          </Col>
        </Row>
       
        <br/><br/>
        <img 
          src={require("./../src/img3/coolor.png")}
          alt="Responsive" 
          className="img-fluid" 
        />
<br/><br/>
        <Row>
      <Col md={6}>
        
          <div>
            <img src= {require('./../src/img3/im4.png')} style={{ width: '100%' }}  alt="Your Image" />
          </div>
        </Col>
        <Col md={6}>
          <div>
            <img src= {require('./../src/img3/im5.png')} style={{ width: '100%' }}  alt="Your Image" />
          </div>
        </Col>
      </Row>
    </Container> 
   
<br/>
<br/>
   
   

   </div>

/* <div><Nav2 />
<div className="full-page">
            
      <img className='imgh' src={require("./../src/home2.png")}  />
      <div className="text-overlay text-nowrap " >
        <h1>شركة رادون</h1>
        <h4 >شركة رادون الوكيل الحصري لدهانات الجزيرة في إلاقليم كردستان العراق</h4>
      </div>
    </div>
     </div>  */
    );
}


export default classnumber;