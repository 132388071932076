import './App.css';
import { Routes, Route} from 'react-router-dom';
import Home from './home';
import Classnumber from './classnumber';
import Contact from './contact';
import About from './abotus';
import Location from './location';







function App() {
  return (
    <div classNameName="App">
      <header classNameName="Radon">
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/classnumber" element={<Classnumber />} />  
        <Route path="/about" element={<About />} />
        <Route path="/location" element={<Location />} />  
        <Route path="/contact" element={<Contact />} />
      </Routes>
      

  <footer className="text-center mt-4 py-3 mtext">
    <p>&copy; xor company. All Rights Reserved.</p>
  </footer>

      
      </header>
    </div>
  );
}

export default App;


{/*
import Lessonspart from './lessonpart';
import Showlessonlink from './showlessolink';
import Nav3 from './Nav3';
import Lessons from './lessons';


<Lessons />
<Lessonspart />
<Nav3 />
<Showlessonlink />
 */}