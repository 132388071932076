
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav2 from './Nav2';
import { Container, Row, Col } from 'react-bootstrap';


const contact = ()=>{

    return( 
        <div>    
          
<Nav2 />

    <Container className="mt-5" >
      <Row className="justify-content-center align-items-center" >
        <Col xs={12} lg={6}>
          <h2>اتصل بنا</h2>
          <p>لا تتردد في التواصل معنا عبر القنوات التالية:</p>
        </Col>
        <Col xs={12} lg={6} className="text-center">
          <ul className="list-unstyled d-flex justify-content-center align-items-center">
            <li className="me-3">
              <a href="https://www.facebook.com/radoncompany" target="_blank" rel="noopener noreferrer">
                <img src={require("./../src/img3/facebook.png")} alt="Facebook" width="30" height="30" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/radon_company" target="_blank" rel="noopener noreferrer">
                <img src={require("./../src/img3/instagram.png")} alt="Instagram" width="30" height="30" />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
        </div>
    );
}


export default contact;